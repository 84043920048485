import { SiteInfo } from '@sitecore-jss/sitecore-jss-nextjs';
import { SiteName, Theme, ThemeUpperCase } from 'src/helpers/Constants';
import { ALL_THEMES, THEME_MAPPING } from 'lib/context/ThemeContext';

export const getTheme = (site: SiteName | SiteInfo): Theme => {
  const siteName = ((site as SiteInfo)?.name as SiteName) ?? site;
  for (let i = 0; i < ALL_THEMES.length; i++) {
    const theme = ALL_THEMES[i];
    const themeSiteList = THEME_MAPPING[theme];
    if (themeSiteList.includes(siteName)) {
      return theme;
    }
  }

  return ALL_THEMES[0];
};

export const getThemeUpper = (site: SiteName | SiteInfo): ThemeUpperCase => {
  return getTheme(site).toUpperCase() as ThemeUpperCase;
};
